import { LockClosedIcon } from '@heroicons/react/solid'
import { useState, useRef, useEffect } from "react"
import { useAuth } from '../contexts/Auth'
import Layout from '../components/Layouts/Layout'
import { AuthProvider } from '../contexts/Auth'
import AlertTemplate from '../components/Notification'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import {useRouter} from 'next/router'
import Image from "next/image"
import { supabase } from '../utils/supabaseClient'
import Meta from '../components/Meta'
export default function Login() {
    const { user, signInGoogle, signOut, signIn } = useAuth();
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [passwordInvalid, setPasswordInvalid] = useState(false)
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [showPolicy, setShowPolicy] = useState(null) 
    const isMounted = useRef(true)
    const router = useRouter()

    const loginUser = async () => { 
        setLoading(true)

        const res  = await signIn({
            email: email,
            password: password,
        })
        if (isMounted.current) {
            if (res.error) {

                setPasswordInvalid(true)
                setLoading(false)
                return
            } 
            setPasswordInvalid(false)
            //router.push 
            console.log("logged in")
            

        }

        () => { isMounted.current = false }

    }

    useEffect(()=> {

      const getProfile = async () => {
        const {data, error} = await supabase
          .from("main_profile")
          .select("id, user_id, account_type")
          .match({user_id: user.id})


          if (router.query?.next === "unsubscribe") {
            router.push("/unsubscribe")
            return
          }

          if (data[0].account_type === "student") {
            router.push("/userpage")
            return
          } else {
            router.push("/dashboard")
          }

      }
        if (user) {
          getProfile()

        }

    },[user])


  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html className="h-full bg-gray-50">
        <body className="h-full">
        ```
      */}
    <div>
    <Meta 
      title="Plaza Login"
      description="Login to your account on Plaza to continue your virtual coaching business"
    
      />
        <div className="absolute inset-y-0 h-full w-full overflow-hidden" aria-hidden="true">
          <div className="relative h-full bg-slate-100">
            <svg
              className="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)" />
            </svg>
            <svg
              className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)" />
            </svg>
          </div>
        </div>
        </div>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-slate-100">
        <div className="max-w-md w-full space-y-8">
          <div>
            <div className="text-center">
              <Image 
                width="60"
                height="60"
                src="https://d28zq0um2frnpx.cloudfront.net/static/plaza-tail-transparent.png"
                alt="plaza icon"
              />
            </div>
            <h2 className="mt-6 text-center text-3xl relative z-20 font-extrabold text-gray-900">Sign in to your account</h2>
            {/* <p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <a href="#" className="font-medium text-zinc-600 hover:text-zinc-500">
                start your 14-day free trial
              </a>
            </p> */}
          </div>
          <form className="mt-8 space-y-6" action="#" method="POST">
            {/* <input type="hidden" name="remember" defaultValue="true" /> */}
            <div className="shadow-sm  rounded-md -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className={`${emailInvalid ? ' border-pink-500 text-pink-600' : 'border-gray-300 placeholder-gray-500 text-gray-900'}  appearance-none  relative block w-full px-3 py-2 border rounded-t-md focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 focus:z-10 sm:text-sm`}
                  placeholder="Email address"
                  onChange={e => setEmail(e.target.value)} 
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className={`${passwordInvalid ? ' invalid:border-pink-500 invalid:text-pink-600' : 'border-gray-300 placeholder-gray-500 text-gray-900'}  shadow-sm  appearance-none relative block w-full px-3 py-2 border  rounded-b-md focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 focus:z-10 sm:text-sm`}
                  placeholder="Password"
                  onChange={e => setPassword(e.target.value)} 
                />
              </div>
            </div>
            {passwordInvalid &&<span className="relative z-20  text-pink-600">Invalid Login Credentials</span>}

            <div className="flex items-center justify-between">
              {/* <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-zinc-600 focus:ring-zinc-500 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div> */}

              <div className="text-sm">
                {/* <a href="#" className="font-medium text-zinc-600 hover:text-zinc-500">
                  Forgot your password?
                </a> */}
              </div>
            </div>

            <div>
              <button
                onClick={loginUser}
                disabled={loading}
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-zinc-900 hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-zinc-500 group-hover:text-zinc-400" aria-hidden="true" />
                </span>
                { loading ? 
                 <svg className="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg> 
                : 
                'Sign in'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

const alertOptions = {
    // you can also just use 'bottom right'
    position: positions.BOTTOM_RIGHT,
    timeout: 3000,
    offset: '10px',
    containerStyle: {'zIndex':'9999'},
    // you can also just use 'fade'
    transition: transitions.FADE,
  }

Login.getLayout = function getLayout(page) {
    return (
        <AlertProvider template={AlertTemplate} {...alertOptions}>
            <AuthProvider>
                <Layout>
                    {page}
                </Layout>
            </AuthProvider>
        </AlertProvider>
    )
  }